<template>
    <section id="testimoniales" class="section bg-default5 ">
        <div v-if="testimonials.title || testimonials.description" class="container d-none d-lg-block">
            <div class="row text-center justify-content-center">
                <div class="col-lg-10">
                    <h2 class="display-1 text-default2 font-main" v-text="testimonials.title"/>
                    <p class="lead text-default2" v-text="testimonials.description"/>
                    <hr class="hrT mb-0"/>
                </div>
            </div>
        </div>
        <vueper-slides
            class="no-shadow"
            :bullets="false"
            :slide-ratio="1 / 3"
            
            :dragging-distance="50"
            autoplay
        >
            <vueper-slide
                v-for="(testimonial,index) in testimonials.items" :key="index"
                :duration="8000"
            >
                <template v-slot:content>
                    <div class="vueperslide__content-wrapper" style="flex-direction: row">
                        <div class="testimonial-content">
                            <div class="testimonial-img d-none d-lg-block">
                                <img :src="urlImage(testimonial.image)" class="img-fluid" alt="Testimonial Slider">
                            </div>
                            <h3 class="text-default2 font-alt d-none d-lg-block">{{testimonial.autor}} <br>
                                <small><span v-if="testimonial.referer">
                                    <a :href="testimonial.referer" target="_blank" class="text-default3" title="Ir a la fuente">Ir a la fuente</a>
                                </span></small>
                            </h3>
                            <blockquote class="blockquote">
                                <p class="lead p-testimonio col-10 text-default2 mx-auto">..."{{testimonial.content}}"...</p>
                            </blockquote>
                        </div>
                    </div>
                </template>
            </vueper-slide>
        </vueper-slides>
        
    </section>
</template>
<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
export default {
    name: "Testimonials",
    components: { VueperSlides, VueperSlide },
    computed: {
        testimonials () {
            return this.$store.state.sections.sections.Testimonial
        },
    },
    data () {
        return {
            slide: 1,
            pauseOnHover: true,
            autoPlaying: true,
            internalAutoPlaying: true,
            controles: false,
        }
    },
    mounted(){
    },
    methods: {
        urlImage (image) {
            return process.env.VUE_APP_URL_REPO + 'get/image?filename='+image
        },
    }
}
</script>