import axios from 'axios'

class LandingService {
  getNavbar () {
    var respuesta = axios.get(process.env.VUE_APP_URL_LANDING + 'get/navbar')
    return respuesta
  }
  getSections () {
    var respuesta = axios.get(process.env.VUE_APP_URL_LANDING + 'get/sections')
    return respuesta
  }
  getImage (image) {
    var respuesta = axios.get(process.env.VUE_APP_URL_REPO + 'get/image', { params: { filename: image }, responseType: 'blob' })
    return respuesta
  }
  getNetworks () {
    var respuesta = axios.get(process.env.VUE_APP_URL_LANDING + 'get/networks')
    return respuesta
  }
}
export default new LandingService()
