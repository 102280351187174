<template>
    <section id="validaciones" class="section section-shaped my-0 overflow-hidden pt-50 pb-300">
        <div class="shape shape-style-3 bg-default1 shape-skew">
        </div>
        <div class="container">
            <div class="row text-center justify-content-center my-5">
                <div class="col-lg-10">
                    <h2 class="display-1 font-main text-white" v-text="workflows.title"/>
                    <p v-if="workflows.description !== null" class="lead text-white" v-html="workflows.description"/>
                </div>
            </div>
        </div>
        
        <div v-for="(workflow,index) in workflows.items" :key="index">

            <div class="row m-0 p-0 d-flex align-items-center effect-opacity">
                <div :class="'col-md-6 m-0 p-0 img-full '+ order1(index)" :style="urlImage(workflow.image)">
                    
                </div>
                <div :class="'col-md-6 p-5 '+ order2(index)">
                    <h3 class="display-5 text-uppercase text-white" v-text="workflow.content.title"/>
                        
                    <p class="lead text-white" v-html="workflow.content.description"/>
                    <router-link
                        v-if="workflow.to"
                        :to="{path: 'workflow/'+workflow.to}"
                        class="btn btn-default3 mt-4"
                    >
                        leer más
                    </router-link>
                </div>
            </div>

        </div>
          
    </section>
</template>
<script>
export default {
    name: "workflow",
    computed: {
        workflows () {
            return this.$store.state.sections.sections.Workflow
        },
    },
    data () {
        return {
        }
    },
    mounted(){
    },
    methods: {
        urlImage (image) {
            var bg = 'background-image: url("'+process.env.VUE_APP_URL_REPO + 'get/image?filename='+image+'");background-size:cover; background-position: center center; height: 300px;'
            return bg
        },
        order1 (index) {
            var num = parseInt(index+1)
            if (num%2 == 0) {
                return 'order-md-2'
            }else{
                return 'order-md-1'
            }
            
        },
        order2 (index) {
            var num = parseInt(index+1)
            if (num%2 == 0) {
                return 'order-md-1'
            }else{
                return 'order-md-2'
            }
        },
    }
}
</script>