<template>
    <div>
        <encabezado />
        <div class="container mt-5">
            <div class="row text-center">
                <div class="col-lg-10 offset-lg-1">
                    <h2 class="display-1 font-main" v-text="'Baja de suscripción'"/>
                    <hr class="hrT"/>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-10 offset-lg-1">
                    <card  id="suscription" gradient="default2" shadow body-classes="p-lg-5 text-default1">
                        <h4 class="mb-1 text-white">En Mare Verum nos interesa tu opinión para poder mejorar nuestros servicios</h4>
                        
                        <div v-if="error && response" class="mt-3 alert alert-danger" role="alert" v-html="response"/>
                        
                        <div v-if="!error && response" class="mt-3 alert alert-success" role="alert">
                            {{response}}
                        </div>
                        <p class="text-white lead">
                            Por favor indica el motivo por el cual solicita la baja.
                        </p>
                        <base-checkbox class="text-white" v-model="motivo.muchos">
                            Recibo muchos correos.
                        </base-checkbox>
                        <base-checkbox class="text-white" v-model="motivo.no_relevantes">
                            No encuentro relevante la información.
                        </base-checkbox>
                        <base-checkbox class="text-white" v-model="motivo.otros">
                            Otros motivos.
                        </base-checkbox>
                        <base-input
                            class="mt-3"
                            v-model="email"
                            alternative
                            placeholder="Dirección de correo *"
                            addon-left-icon="ni ni-email-83"
                        />
                        
                        <base-button
                            class="btn-outline-default3"
                            round
                            block
                            size="lg"
                            @click="removeSuscription"
                        >
                            Darme de baja
                        </base-button>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Encabezado from '@/layout/AppEncabezado'
    export default  {
        name: "Unsuscribe",
        components: {
            encabezado: Encabezado
        },
        data() {
            return {
                error: null,
                response: '',
                unSuscription: {},
                email: null,
                motivo: {
                    muchos: false,
                    no_relevantes: false,
                    otros: false
                }
            }
        },
        mounted(){
        },
        methods: {
            removeSuscription () {
                this.response = ''
                this.error = false
                
                if (!this.email) {
                    this.error = true
                    this.response += '* El correo es obligatorio.<br>'
                }
                
                if (this.email) {
                    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    const result = re.test(String(this.email).toLowerCase());
                    if (!result) {
                        this.error = true
                        this.response += '* Verifique que el correo sea valido.'
                    }
                }

                if (!this.error) {
                    
                    this.unSuscription.email = String(this.email).toLowerCase()
                    this.unSuscription.motivo = this.motivo
                    var removeSuscription = this.$store.state.services.suscriptionService.SuscriptionRemove(this.unSuscription)
                    removeSuscription.then(response => {
                        if (response.data.code = 200) {
                            this.error = false
                            this.response = 'Su correo fue eliminado de la lista.'                  
                            this.motivo = {
                                muchos: false,
                                no_relevantes: false,
                                otros: false
                            }
                            this.email = null
                            this.unSuscription = {}
                        } else {
                            this.error = true
                            this.response = 'Upss. Ha ocurrido un error intente más tarde.'
                            this.motivo = {
                                muchos: false,
                                no_relevantes: false,
                                otros: false
                            }
                            this.email = null
                            this.unSuscription = {}
                        }
                    }, error => {
                        this.error = true
                        this.response = error.response.data.message
                        this.motivo = {
                            muchos: false,
                            no_relevantes: false,
                            otros: false
                        }
                        this.email = null
                        this.unSuscription = {}
                    })
                }
            }
        }
    }
</script>
<style scoped>
</style>