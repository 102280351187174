<template>
    <section id="equipo" class="section section-lg pt-0">
        <div class="container">
            <div class="row text-center justify-content-center my-5">
                <div class="col-lg-10">
                    <h2 class="display-1 font-main" v-text="team.title"/>
                    <p class="lead" v-text="team.description"/>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <div class="row row-grid">
                        <div :class="grid() + ' my-5'" v-for="item in team.items" :key="item.member">
                            <div class="card h-100 card-profile border-0 card-lift--hover shadow">
                                <div class="row justify-content-center">
                                    <div class="col-lg-3 order-lg-2">
                                        <div class="card-profile-image">
                                            <img :src="urlImage(item.image)" class="rounded-circle"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-header bg-default4 mb-5">

                                </div>
                                <div class="card-body pt-0 text-center">
                                    <h3 class="mb-0 font-alt" v-text="item.member"/>
                                    <span class="small font-alt text-uppercase text-muted" v-text="item.cargo" />
                                    <p class="my-5" v-html="item.presentation" />
                                </div>
                                <div class="card-footer  border-0 bg-white text-center">
                                    <ul class="social mb-0 list-inline mt-3">
                                        <li class="list-inline-item" v-for="network in item.networks" :key="network.name">
                                            <a :href="network.to" class="social-link" target="_blank">
                                                <i :class="'fa ' + network.icon" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "team",
    computed: {
        team () {
            return this.$store.state.sections.sections.Team
        },
    },
    data () {
        return {
        }
    },
    mounted(){
    },
    methods: {
        urlImage (image) {
            return process.env.VUE_APP_URL_REPO + 'get/image?filename='+image
        },
        grid(){
            const cant = this.team.items.length
            var respuesta
            switch (cant) {
                case 0:
                    respuesta = 'd-none'
                    break;
                case 1:
                    respuesta = 'col-md-8 offset-md-2'
                    break;
                case 2:
                    respuesta = 'col-md-6'
                    break;
                case 3:
                    respuesta = 'col-md-4'
                    break;
                case 4:
                    respuesta = 'col-md-3'
                    break;
                default:
                    respuesta = 'col-md-4'
            }
            return respuesta
        }
    }
}
</script>