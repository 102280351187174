function initialState () {
	return {
		networks: [

		],
	}
    
}
export const socials = {
  namespaced: true,
  state: initialState,
  actions: {

  },
  mutations: {
    guardarNetworks (state, networks) {
      state.networks = networks
    },
  },
}