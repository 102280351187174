<template>
    <footer class="footer has-cards">
        <div class="container container-lg">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <card  id="contacto" gradient="default2" shadow body-classes="p-lg-5 text-default1">
                        <h4 class="mb-1 text-white">¿Tienes dudas?</h4>
                        <p class="text-white lead">
                            Te invitamos a que leas nuestras preguntas frecuentes. Si allí no encuentras lo que buscabas, completa el formulario y a la brevedad te responderemos.
                        </p>
                        <div v-if="error && response" class="alert alert-danger" role="alert" v-html="response"/>
                        
                        <div v-if="!error && response" class="alert alert-success" role="alert">
                            {{response}}
                        </div>
                        <base-input
                            v-model.trim="name" 
                            class="mt-5"
                            alternative
                            placeholder="Tu nombre *"
                            addon-left-icon="ni ni-single-02"
                        />
                        <base-input
                            v-model="email"
                            alternative
                            placeholder="Dirección de correo *"
                            addon-left-icon="ni ni-email-83"
                        />
                        <base-input class="mb-4">
                            <textarea
                                v-model="message"
                                class="form-control form-control-alternative"
                                rows="4"
                                cols="80"
                                placeholder="Escriba su consulta *"
                            />
                        </base-input>
                        <base-button
                            class="btn-outline-default3"
                            round
                            block
                            size="lg"
                            @click="SendMessageContact"
                        >
                            Enviar Mensaje
                        </base-button>
                    </card>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row row-grid align-items-center my-md">
                <div class="col-lg-7">
                    <h3 class="text-default2 font-weight-light mb-2 font-main">¡Gracias por visitarnos!</h3>
                    <h4 class="mb-0 font-weight-light text-default2">Ingresá a nuestras redes sociales.</h4>
                </div>
                <div class="col-lg-5 text-lg-center btn-wrapper">
                    <a v-for="network in socials" :key="network.id" target="_blank" rel="noopener" :href="network.to"
                       class="btn btn-outline-default2 btn-lg" data-toggle="tooltip"
                       :data-original-title="network.title">
                        <i :class="'fa fa-3x '+ network.icon"></i>
                    </a>
                </div>
            </div>
            <hr>
            <div class="row align-items-center justify-content-md-between">
                <div class="col-12">
                    <div class="copyright">
                        &copy; {{year}} {{ empresa }} | developed by 
                        <a href="" target="_blank" rel="noopener" class="text-default2">Amaltea Soluciones Tecnológicas</a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>

export default {
    name: 'app-footer',
    data () {
        return {
            logo1: process.env.VUE_APP_LOGO1,
            logo2: process.env.VUE_APP_LOGO2,
            empresa: process.env.VUE_APP_NOMBRE_EMPRESA,
            year: new Date().getFullYear(),
            name: null,
            email: null,
            error: false,
            message: null,
            response: '',
            contact: {}
        }
    },
    computed: {
        socials () {
            return this.$store.state.socials.networks
        }
    },
    methods: {
        SendMessageContact () {
            this.response = ''
            this.error = false
            if (!this.name) {
                this.error = true
                this.response += '* El nombre es obligatorio.<br>'
            }
            if (!this.email) {
                this.error = true
                this.response += '* El correo es obligatorio.<br>'
            }
            if (!this.message) {
                this.error = true
                this.response += '* El mensaje es obligatorio.'
            }

            if (this.email) {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                const result = re.test(String(this.email).toLowerCase());
                if (!result) {
                    this.error = true
                    this.response += '* Verifique que el correo sea valido.'
                }
            }

            if (!this.error) {
                this.contact.name = this.name
                this.contact.email = String(this.email).toLowerCase()
                this.contact.message = this.message

                var sendMessage = this.$store.state.services.contactService.SendMessage(this.contact)
                sendMessage.then(response => {
                    if (response.data.code = 200) {
                        this.error = false
                        this.response = 'Mensaje enviado, a la brevedad recibira la respuesta de su consulta.'                  
                        this.name = null
                        this.email = null
                        this.message = null
                        this.contact = {}
                    } else {
                        this.error = true
                        this.response = 'Upss. Ha ocurrido un error intente más tarde.'
                        this.name = null
                        this.email = null
                        this.message = null
                        this.contact = {}
                    }
                }, error => {
                    this.error = true
                    this.response = 'Upss. Ha ocurrido un error intente más tarde.'
                    this.name = null
                    this.email = null
                    this.message = null
                    this.contact = {}
                })
            }
            
        },
    },
}
</script>
<style scoped>
    .font-main {
        font-family: 'Infinity', sans-serif;
        font-size: 3.5rem;
    }  
</style>
