<template>
    <section id="suscripciones" class="section section-lg pt-0 ">
        <div class="container">
            <card 
                no-body
                shadow-size="lg"
                class="border-0 bg-default4"
            >
                <div class="p-5">
                    <div class="row align-items-center">
                        <div class="col-lg-8">
                            <h3 class="text-default2 font-main" v-text="suscription.title"/>
                            <p class="lead text-default2 mt-3" v-html="suscription.description"/>
                        </div>
                        <div class="col-lg-3 ml-lg-auto">
                            <router-link
                                v-if="suscription.button.requiere"
                                :to="{path: '/suscription'}"
                                class="btn btn-outline-default2 btn-block"
                            >
                                {{suscription.button.text}}
                            </router-link>
                        </div>
                    </div>
                </div>
            </card>
        </div>
    </section>
</template>
<script>
export default {
    name: "suscription",
    computed: {
        suscription () {
            return this.$store.state.sections.sections.Suscription
        }
    },
    data () {
        return {
        }
    },
    mounted(){
    },
    methods: {
    }
}
</script>