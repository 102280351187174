import Vue from 'vue';
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import Argon from './plugins/argon-kit'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSmoothScroll from 'vue2-smooth-scroll'




Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.use(Argon)

Vue.use(VueSmoothScroll,{
  duration: 2000,
  updateHistory: false,
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")


