<template>
    <section id="acerca-de" class="section bg-default5 text-white">
        <div class="container">
            <div class="row mt-lg-5">
                <div class="col-md-6 justify-content-center order-md-2">
                    <div class="row mb-3">
                        <div class="col-lg-10">
                            
                            <h2 class="display-1 font-main text-default2" v-text="aboutus.title"/>
                            <hr class="hrT"/>
                            <p class="lead text-default2" v-html="aboutus.description"/>

                            <router-link
                                v-if="aboutus.button.requiere == true"
                                :to="aboutus.button.to"
                                class="btn btn-default3 mt-4"
                            >
                                mas sobre nosotros
                            </router-link>
                            
                        </div>
                    </div>
                    
                </div>
                <div class="col-md-6 justify-content-center order-md-1">
                    <div class="card bg-default3 shadow border-0">
                        <img v-lazy="urlImage(aboutus.image_main)" class="card-img-top">
                        <blockquote class="card-blockquote">
                            <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95"
                                    class="svg-bg">
                                <polygon points="0,52 583,95 0,95" class="fill-default3"></polygon>
                                <polygon points="0,42 583,95 683,0 0,95" opacity=".2" class="fill-default3"></polygon>
                            </svg>
                            <p class="text-italic text-white" v-html="aboutus.image_description"/>
                        </blockquote>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "about",
    computed: {
        aboutus () {
            return this.$store.state.sections.sections.About
        },
    },
    data () {
        return {
        }
    },
    mounted(){
    },
    methods: {
        urlImage (image) {
            return process.env.VUE_APP_URL_REPO + 'get/image?filename='+image
        },
    }
}
</script>
