function initialState () {
	return {
		sections: [

		],
	}
    
}
export const sections = {
  namespaced: true,
  state: initialState,
  actions: {

  },
  mutations: {
    guardarSections (state, sections) {
      state.sections = sections
    },
  },
}
