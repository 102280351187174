<template>
    <footer class="footer has-cards">
        <div class="container container-lg">
            <div class="row justify-content-center">
                <div class="col-lg-8" style="height:150px">
                    
                </div>
            </div>
        </div>
        <div class="container"  id="contacto">
            <div class="row row-grid align-items-center my-md">
                <div class="col-lg-7">
                    <h3 class="text-default2 font-weight-light mb-2 font-main">¡Gracias por visitarnos!</h3>
                    <h4 class="mb-0 font-weight-light text-default2">Ingresá a nuestras redes sociales.</h4>
                </div>
                <div class="col-lg-5 text-lg-center btn-wrapper">
                    <a v-for="network in socials" :key="network.id" target="_blank" rel="noopener" :href="network.to"
                       class="btn btn-outline-default2 btn-lg" data-toggle="tooltip"
                       :data-original-title="network.title">
                        <i :class="'fa fa-3x '+ network.icon"></i>
                    </a>
                </div>
            </div>
            <hr>
            <div class="row align-items-center justify-content-md-between">
                <div class="col-12">
                    <div class="copyright">
                        &copy; {{year}} {{ empresa }} | developed by 
                        <a href="" target="_blank" rel="noopener" class="text-default2">Amaltea Soluciones Tecnológicas</a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
    name: 'app-footer-intro',
    data () {
        return {
            logo1: process.env.VUE_APP_LOGO1,
            logo2: process.env.VUE_APP_LOGO2,
            empresa: process.env.VUE_APP_NOMBRE_EMPRESA,
            year: new Date().getFullYear(),
        }
    },
    computed: {
        socials () {
            return this.$store.state.socials.networks
        }
    },
    methods: {
        SendMessageContact () {
            this.contact = {}
        },
    },
}
</script>
