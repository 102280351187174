import axios from 'axios'

class ArticlesService {
    getContent (slug) {
        var respuesta = axios.get(process.env.VUE_APP_URL_LANDING + 'get/article/'+slug)
        return respuesta
    }
    getAll () {
        var respuesta = axios.get(process.env.VUE_APP_URL_LANDING + 'get/articles')
        return respuesta
    }
}
export default new ArticlesService()