<template>
    <div>
        <encabezado />
        <div class="container mt-5">
            <div class="row text-center">
                <div class="col-lg-10 offset-lg-1">
                    <h2 class="display-1 font-main" v-text="'Inscripción'"/>
                    <hr class="hrT"/>
                </div>
            </div>
            <div class="row">
                <div class="contentInner px-2">
                    <h3>Proceso de inscripción</h3>
                    <p>1- Completar el formulario electrónico de inscripción, al final de esta página. (IMPORTANTE: completarlo el mismo día que se va a realizar el pago.)</p>
                    <p>2- Realizar el pago por el medio correspondiente.</p>

                    <p>3- Una vez efectuado el pago, comunicarse con Mare Verum vía email o inbox a la página de Facebook, y enviar el comprobante del pago efectuado.</p>

                    <p>Se debe enviar a educarenfamiliaunschooling@gmail.com la siguiente documentación escaneada en el transcurso del mes posterior a la inscripción:</p>

                    <p>*Partida de nacimiento del/los alumno/s inscriptos.</p>

                    <p>*DNI del/los alumno/s inscriptos.</p>

                    <p>*DNI de los padres.</p>

                    <p>*En el caso de alumnos que se inscriban luego de comenzado el ciclo escolar, deberán presentar boletín de calificaciones o evidencias que acrediten estudios durante los meses previos a la inscripción en Mare Verum.</p>

                    

                    <p>El proceso de inscripción se da por finalizado una vez que esté efectuado el pago.</p>

                    <p>No se entregarán los certificados finales si la documentación necesaria no fue enviada o no está completa. Tampoco se entregarán si no se presenta el portfolio de evidencias correspondiente al finalizar el año.</p>

                    <p>Una vez completado el proceso de inscripción, en el transcurso de las 48 hs siguientes recibirán una serie de emails informando cómo se debe acceder al material de estudio. Los certificados de inscripción se entregarán en el transcurso de la semana.</p>

                    <h3 class="text-center">COMPLETE EL FORMULARIO SÓLO SI ESTÁ SEGURO DE QUERER INSCRIBIRSE AL PROGRAMA MARE VERUM.</h3>

                    <div class="col-lg-6 offset-lg-3 mt-5">
                        <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSeRyJBAaUwVmM9xIyiZ_ysHKN3KL1bl6rK656cHEJIh9ZYPCg/viewform?fbzx=9164848940053354000"
                            target="_blank"
                            class="btn btn-block btn-outline-default4 mt-4"
                        >
                            Completar el formulario de inscripción
                        </a>
                    </div>
                
                </div>
                    
            </div>
        </div>
    </div>
</template>
<script>
    import Encabezado from '@/layout/AppEncabezado'
    export default  {
        name: "Inscription",
        components: {
            encabezado: Encabezado
        },
        data() {
            return {
            }
        },
        mounted(){
        },
        watch: {
        },
        methods: {
        }
    }
</script>
<style scoped>
</style>