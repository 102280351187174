<template>
    <div>
        <encabezado />
        <div class="container mt-5">
            <div class="row text-center">
                <div class="col-lg-10 offset-lg-1">
                    <h2 class="display-1 font-main" v-text="about.title_section"/>
                    <p class="lead" v-text="about.description_section"/>
                    <hr class="hrT"/>
                </div>
            </div>
            <div class="row">
                <div class="contentInner px-2" v-html="about.main_content" />
                    
            </div>
        </div>
    </div>
</template>
<script>
    import Encabezado from '@/layout/AppEncabezado'
    export default  {
        name: "About",
        components: {
            encabezado: Encabezado
        },
        data() {
            return {
               about: {}
            }
        },
        mounted(){
            this.getContent()
        },
        methods: {
            getContent () {
                var sections = this.$store.state.services.aboutService.getContent()
                sections.then(response => {
                    if (response.data.code = 200) {
                        
                        this.about = response.data.data.about
                        if (this.about !== null) {
                            if(this.about.main_content !== null){
                                this.about.main_content = this.about.main_content.replace('<img ', '<img class="img-thumbnail m-5 p-0"')
                            }else{
                                this.$router.push('/')
                            }
                        }else{
                            this.$router.push('/')
                        }
                        

                        
                    }
                })
            
            },
        }
    }
</script>
<style scoped>
</style>