<template>
    <b-carousel id="carrusel"
        v-model="slide"
        :interval="8000"
        :controls="controles"
        background="#000000"
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333;"
    >
        <b-carousel-slide v-for="(slide,i) in slider.sliders" :key="i"
            :img-src="urlImage(slide.image)"
            class="img-overlay"
        >
            <template v-if="slide.only_img !== true">
                <div class="location-1">
                    <h2 class="display-1 text-white text-start title-slide" v-text="slide.content.title"/>
                    <h4 class="display-4 d-none d-lg-block text-white text-start" v-text="slide.content.subtitle"/>
                    <p class="lead d-none d-lg-block" v-html="slide.content.description"/>
                    <router-link
                        v-if="slide.button.requiere"
                        :to="{path: slide.button.to}"
                        class="btn btn-outline-white1"
                    >
                        <span class="nav-link-inner--text">{{slide.button.text}}</span>
                    </router-link>
                </div>
            </template>
            <template v-else>
                <div :class="slide.button.location+' d-flex h-100'">
                    <router-link
                        v-if="slide.button.requiere"
                        :to="{path: slide.button.to}"
                        class="btn btn-outline-white1"
                        style="max-height: 45px"
                    >
                        <span class="nav-link-inner--text">{{slide.button.text}}</span>
                    </router-link>
                </div>
            </template>
        </b-carousel-slide>
    </b-carousel>
</template>
<script>

export default {
    name: "carousel",
    computed: {
        slider () {
            return this.$store.state.sections.sections.Carousel
        },
    },
    data () {
        return {
            slide: 1,
            pauseOnHover: true,
            autoPlaying: true,
            internalAutoPlaying: true,
            controles: false,
        }
    },
    mounted(){
        this.controls()
    },
    methods: {
        controls () {
            if (this.slider.sliders.length >= 2) {
                this.controles = true
            } else {
                this.controles = false
            }
        },
        urlImage (image) {
            return process.env.VUE_APP_URL_REPO + 'get/image?filename='+image
        },
    }
}
</script>

