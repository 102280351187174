<template>
    <div>
        <encabezado />
        <div class="container mt-5">
            <div class="row text-center">
                <div class="col-lg-10 offset-lg-1">
                    <h2 class="display-1 font-main" v-text="content.title"/>
                    <hr class="hrT"/>
                </div>
            </div>
            <div class="row">
                <div class="contentInner px-2" v-html="content.main_content" />
                    
            </div>
        </div>
    </div>
</template>
<script>
    import Encabezado from '@/layout/AppEncabezado'
    export default  {
        name: "Prices",
        components: {
            encabezado: Encabezado
        },
        data() {
            return {
               content: {}
            }
        },
        mounted(){
            this.getContent()
        },
        watch: {
            '$route.params.slug': function () {
                this.getContent()
            },
        },
        methods: {
            getContent () {
                if (this.$route.params.slug && this.$route.params.slug !== '') {
                    const slug = this.$route.params.slug
                    var sections = this.$store.state.services.pricesService.getContent(slug)
                    sections.then(response => {
                        if (response.data.code = 200) {
                            
                            this.content = response.data.data.content
                            if (this.content !== null) {
                                if(this.content.main_content !== null){
                                    this.content.main_content = this.content.main_content.replace('<img ', '<img class="img-thumbnail m-5 p-0"')
                                }else{
                                    this.$router.push('/')
                                }
                            }else{
                                this.$router.push('/')
                            }
                            
                        }
                    })
                }else{
                    this.$router.push('/')
                }
                
                
            
            },
        }
    }
</script>
<style scoped>
</style>