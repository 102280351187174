import axios from 'axios'

class SuscriptionService {
    isEnabled () {
        var respuesta = axios.get(process.env.VUE_APP_URL_LANDING + 'suscription/enabled')
        return respuesta
    }
    SendNewSuscription (data) {
        var respuesta = axios.post(process.env.VUE_APP_URL_LANDING + 'suscription/add', { data: JSON.stringify(data) })
        return respuesta
    }
    SuscriptionRemove (data) {
        var respuesta = axios.post(process.env.VUE_APP_URL_LANDING + 'suscription/remove', { data: JSON.stringify(data) })
        return respuesta
    }
}
export default new SuscriptionService()