<template>
    <section id="costos" class="section section-lg pt-0">
        <div class="container">
            <div class="row text-center justify-content-center my-5">
                <div class="col-lg-10">
                    <h2 class="display-1 font-main" v-text="price.title"/>
                    <p class="lead" v-text="price.description"/>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <div class="row row-grid">
                        <div :class="grid()+ ' text-center'" v-for="item in price.items" :key="item.slug">
                            <div class="card h-100 border-0 card-lift--hover shadow">
                                <div class="card-header bg-pink text-default2">
                                    <h3 class="h4 my-0 font-weight-normal text-uppercase" v-text="item.group"/>
                                </div>
                                <div class="card-body">
                                    <h4 class="display-4" v-text="item.title"/>
                                    <div class="mt-2 mb-4" v-html="item.description" />
                                </div>
                                <div class="card-footer  border-0 bg-white text-center">
                                    <h5 class="display-3 font-alt card-title pricing-card-title"><small>{{item.currency}}</small> <strong>{{item.amount}}</strong></h5>
                                    <div class="align-self-center" style="margin-top: auto;">
                                    <router-link
                                        v-if="item.slug"
                                        :to="{path: 'prices/'+item.slug}"
                                        :class="'btn btn-lg btn-block btn-outline-default3'"
                                        
                                    >
                                        Quiero Inscribirme
                                    </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p class="lead text-muted" v-text="price.legal_section"/>
                    <p class="lead text-muted">
                        Para mas información ingrese a <router-link :to="{path: 'articles/legales'}">Legales.</router-link>
                    </p>
                </div>
            </div>
            <hr/>
            <div v-if="price.image1 || price.image2" class="row">
                <div v-if="price.image1" :class="gridImg()">
                    <img class="card-img-top p-0 m-0" :src="urlImage(price.image1)">
                </div>
                <div v-if="price.image2" :class="gridImg()">
                    <img class="card-img-top p-0 m-0" :src="urlImage(price.image2)">
                </div>
            </div>
        </div>
        
    </section>
</template>
<script>
export default {
    name: "price",
    computed: {
        price () {
            return this.$store.state.sections.sections.Price
        },
    },
    data () {
        return {
        }
    },
    mounted(){
    },
    methods: {
        urlImage (image) {
            return process.env.VUE_APP_URL_REPO + 'get/image?filename='+image
        },
        grid(){
            const cant = this.price.items.length
            var respuesta
            switch (cant) {
                case 0:
                    respuesta = 'd-none'
                    break;
                case 1:
                    respuesta = 'col-md-8 offset-md-2'
                    break;
                case 2:
                    respuesta = 'col-md-6'
                    break;
                case 3:
                    respuesta = 'col-md-4'
                    break;
                case 4:
                    respuesta = 'col-md-3'
                    break;
                default:
                    respuesta = 'col-md-4'
            }
            return respuesta
        },
        gridImg(){
            var count = 0
            if (this.price.image1) {
             count = count + 1  
            }
            if (this.price.image2) {
             count = count + 1  
            }
            var respuesta
            switch (count) {
                case 0:
                    respuesta = 'd-none'
                    break;
                case 1:
                    respuesta = 'col-12'
                    break;
                case 2:
                    respuesta = 'col-md-6'
                    break;
            }
            return respuesta
        },
    }
}
</script>
<style>
ul,ol{
    padding-left: 0;
    list-style: none;
}
</style>