// General

import landingService from './landing/landing.service'
import aboutService from './landing/about.service'
import serviceService from './landing/services.service'
import workflowService from './landing/workflow.service'
import pricesService from './landing/prices.service'
import articlesService from './landing/articles.service'
import contactService from './contact/contact.service'
import suscriptionService from './suscription/suscription.service'

export default {
  landingService,
  aboutService,
  serviceService,
  workflowService,
  pricesService,
  articlesService,
  contactService,
  suscriptionService,
}
