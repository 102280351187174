import Vue from 'vue'
import Vuex from 'vuex'
import services from '@/services'
import { navbar } from './navbar.module'
import { sections } from './sections.module'
import { socials } from './socials.module'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    services,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    navbar,
    sections,
    socials,
  },
})
