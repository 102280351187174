<template>
  <div id="app">
    <router-view name="header"></router-view>
    <main>
      <button @click="scrollTop" v-show="visible" class="btn rounded-circle btn-default4 bottom-right" >
        <i class="fa fa-chevron-up"></i>
      </button>
      <fade-transition origin="center" mode="out-in" :duration="250">
        <router-view/>
      </fade-transition>
    </main>
    <router-view name="footer"></router-view>
  </div>
</template>
<script>
import { FadeTransition } from "vue2-transitions";


export default {
  components: {
    FadeTransition
  },
  data () {
    return {
      visible: false
    }
  },
  created () {
  },
  mounted: function () {
    this.getNavbar()
    this.getNetworks()
    window.addEventListener('scroll', this.scrollListener)
  },
  updated: function () {
  },
  beforeDestroy: function () {
    window.removeEventListener('scroll', this.scrollListener)
  },
  methods: {
    scrollTop: function () {
      this.intervalId = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(this.intervalId)
        }
        window.scroll(0, window.pageYOffset - 50)
      }, 20)
    },
    scrollListener: function (e) {
      this.visible = window.scrollY > 150
    },
    getNavbar () {
      var navbar = this.$store.state.services.landingService.getNavbar()
      navbar.then(response => {
        if (response.data.code = 200) {
          this.$store.commit('navbar/guardarNavbar', response.data.data.navbar)
        }
      })
    },
    getNetworks () {
      var navbar = this.$store.state.services.landingService.getNetworks()
      navbar.then(response => {
        if (response.data.code = 200) {
          this.$store.commit('socials/guardarNetworks', response.data.data.networks)
        }
      })
    },
  }
};
</script>
<style scoped>

@font-face {
  font-family: "Infinity";
  src: local("Infinity"),
        url(./assets/vendor/fonts/Infinite_Stroke.eot) format("embedded-opentype"),
        url(./assets/vendor/fonts/Infinite_Stroke.otf) format("opentype");
}

.bottom-right {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer!important;
  z-index: 10000;
}
.rounded-circle {
  width: 50px;
  height: 50px;
  padding: 0px;
}
</style>

