<template>
    <div>
        <encabezado />
        <div class="container mt-5">
            <div class="row text-center">
                <div class="col-lg-10 offset-lg-1">
                    <h2 class="display-1 font-main" v-text="'Suscribite al canal'"/>
                    <hr class="hrT"/>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-10 offset-lg-1">
                    <card  id="suscription" gradient="default2" shadow body-classes="p-lg-5 text-default1">
                        <h4 class="mb-1 text-white">¿Qué estaras recibiendo?</h4>
                        <p class="text-white lead">
                            Por este canal estaras recibiendo correos de Mare Verum para mantenerte al día con todas las noticias y novedades que tengamos para vos.
                        </p>
                        <p class="text-white lead">
                            También puedes pedir la baja de la lista en cualquier momento ingresando en el <router-link class="text-default4" :to="{path: '/unsuscribe'}" >formulario</router-link>.
                        </p>
                        <div v-if="error && response" class="alert alert-danger" role="alert" v-html="response"/>
                        
                        <div v-if="!error && response" class="alert alert-success" role="alert">
                            {{response}}
                        </div>
                        <base-input
                            v-model.trim="name" 
                            class="mt-5"
                            alternative
                            placeholder="Tu nombre *"
                            addon-left-icon="ni ni-single-02"
                        />
                        <base-input
                            v-model="email"
                            alternative
                            placeholder="Dirección de correo *"
                            addon-left-icon="ni ni-email-83"
                        />
                        <base-button
                            class="btn-outline-default3"
                            round
                            block
                            size="lg"
                            @click="SendNewSuscription"
                        >
                            Registrarme
                        </base-button>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Encabezado from '@/layout/AppEncabezado'
    export default  {
        name: "Suscripciones",
        components: {
            encabezado: Encabezado
        },
        data() {
            return {
                error: null,
                response: '',
                newSuscription: {},
                name: null,
                email: null
            }
        },
        mounted(){
            this.suscriptionEnabled()
        },
        methods: {
            suscriptionEnabled () {
                var enabled = this.$store.state.services.suscriptionService.isEnabled()
                enabled.then(response => {
                    if (response.data.code = 200) {
                        if (!response.data.data.enabled) {
                            this.$router.push('/')
                        }
                    
                    }else{
                        this.$router.push('/')
                    }
                }, error => {
                    this.$router.push('/')
                })
            },
            SendNewSuscription () {
                this.response = ''
                this.error = false
                if (!this.name) {
                    this.error = true
                    this.response += '* El nombre es obligatorio.<br>'
                }
                if (!this.email) {
                    this.error = true
                    this.response += '* El correo es obligatorio.<br>'
                }
                
                if (this.email) {
                    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    const result = re.test(String(this.email).toLowerCase());
                    if (!result) {
                        this.error = true
                        this.response += '* Verifique que el correo sea valido.'
                    }
                }

                if (!this.error) {
                    this.newSuscription.name = this.name
                    this.newSuscription.email = String(this.email).toLowerCase()
                    var sendSuscription = this.$store.state.services.suscriptionService.SendNewSuscription(this.newSuscription)
                    sendSuscription.then(response => {
                        if (response.data.code = 200) {
                            this.error = false
                            this.response = 'Gracias por suscribirte, estaras recibiendo todas las noticias y novedades en tu casilla de correo.'                  
                            this.name = null
                            this.email = null
                            this.newSuscription = {}
                        } else {
                            this.error = true
                            this.response = response.data.message
                            this.name = null
                            this.email = null
                            this.newSuscription = {}
                        }
                    }, error => {
                        this.error = true
                        this.response = error.response.data.message
                        this.name = null
                        this.email = null
                        this.newSuscription = {}
                    })
                }
            }
        }
    }
</script>
<style scoped>
</style>