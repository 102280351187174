<template>
    <header class="header-global">
      <base-nav class="navbar-main" transparent type="" effect="light" expand>
        <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
          <img :src="logo1" :alt="empresa">
        </router-link>
        <div class="row" slot="content-header" slot-scope="{closeMenu}">
          <div class="col-6 collapse-brand">
            <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">  
              <img :src="logo2" :alt="empresa">
            </router-link>  
          </div>
          <div class="col-6 collapse-close">
            <close-button @click="closeMenu"></close-button>
          </div>
        </div>

        <ul class="navbar-nav navbar-nav-hover align-items-lg-center ml-lg-auto">
          <li class="nav-item">
            <router-link to="/" class="nav-link">
              Inicio
              <!--i class="fa fa-home" aria-hidden="true"></i-->
            </router-link>
          </li>
          <div
            v-for="item in navbar"
            v-bind:key="item.slug"
          >
          <li class="nav-item" v-if="item.childrens.length === 0 && item.scroll_in_home">
            <a :href="'#'+item.scroll_to" class="nav-link" v-smooth-scroll>
              <span class="nav-link-inner--text">{{ item.name }}</span>
            </a>
          </li>
          <base-dropdown tag="li" class="nav-item" v-else-if="item.childrens.length >= 1">
            <a slot="title" class="nav-link" data-toggle="dropdown" role="button">
              <span class="nav-link-inner--text">{{ item.name }}</span>
            </a>
            <router-link
              v-for="childen in item.childrens"
              v-bind:key="childen.slug"
              :to="childen.to"
              class="dropdown-item"
            >
              {{ childen.name}}
            </router-link>
          </base-dropdown>
          
          <li class="nav-item" v-else>
            <router-link :to="item.toin" class="nav-link" v-if="item.is_external === false">
              <span class="nav-link-inner--text">{{ item.name }}</span>
            </router-link>
            <a :href="item.toout" target="_blank" class="nav-link" v-else>
              <span class="nav-link-inner--text">{{ item.name }}</span>
            </a>
          </li>
          </div> 
          <li class="nav-item d-none d-lg-block ml-lg-4">
            <router-link to="/inscription" class="btn btn-neutral btn-icon">
              <span class="btn-inner--icon">
                <i class="fa fa-file-signature"></i>
              </span>
              <span class="nav-link-inner--text">Inscribite !!</span>
            </router-link>
          </li>
        </ul>
      </base-nav>
    </header>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import BaseDropdown from "@/components/BaseDropdown";
import CloseButton from "@/components/CloseButton";

export default {
  name: 'app-header',
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown
  },
  data () {
    return {
      logo1: process.env.VUE_APP_LOGO1,
      logo2: process.env.VUE_APP_LOGO2,
      empresa: process.env.VUE_APP_NOMBRE_EMPRESA,
    }
  },
  computed: {
    navbar () {
      return this.$store.state.navbar.navbar
    }
  },
  mounted () {
  }
};
</script>
