<template>
    <div>
        <div v-for="componente in componentes" :key="componente.id" :id="componente.name">
            <component
                :is="componente.name"
            />
        </div>
    </div>
</template>

<script>
import About from '@/components/core/About';
import Articles from '@/components/core/Articles';
import Carousel from '@/components/core/Carousel';
import Paralax from '@/components/core/Paralax';
import Price from '@/components/core/Price';
import Services from '@/components/core/Services';
import Suscription from '@/components/core/Suscription';
import Team from '@/components/core/Team';
import Testimonial from '@/components/core/Testimonial';
import Workflow from '@/components/core/Workflow';
export default {
    name: "home",
    components: {
        About,
        Articles,
        Carousel,
        Paralax,
        Price,
        Services,
        Suscription,
        Team,
        Testimonial,
        Workflow
    },
    computed: {
    },
    data () {
        return {
            componentes: []
        }
    },
    mounted(){
        this.getSections()
    },
    methods: {
        getSections () {
            var sections = this.$store.state.services.landingService.getSections()
            sections.then(response => {
                if (response.data.code = 200) {
                    this.$store.commit('sections/guardarSections', response.data.data.sections)
                    this.renderComponents()
                }
            })
        
        },
        renderComponents() {
            let sections = this.$store.state.sections.sections
            for (let section in sections) {
                
                if (sections[section] !== null) {
                    let elemento = []
                    elemento['name'] = section
                    elemento['id'] = sections[section].order
                    this.componentes.push(elemento)
                }
            }
        }
    }
    
};
</script>
<style>
    .font-main {
        font-family: 'Infinity', sans-serif;
        font-size: 3.2rem;
    }  
    .title-slide {
        font-family: 'Infinity', sans-serif;
        font-size: 3.5rem;
       
    }
    .font-alt {
        font-family: 'Source Sans Pro', sans-serif;
    }
    @media (max-width: 720px) {
        .title-slide {
            font-size: 2.5rem!important;
        }
        .font-main {
            font-size: 3rem!important;
        }
        .p-testimonio{
            font-size: 14px!important;
        }  
    }
    @media (max-width: 740px) {
        .p-testimonio{
            font-size: 14px!important;
        }  
    }
    @media (min-width: 721px) and (max-width:992px){
        .title-slide {
            font-size: 2.5rem!important;
        }
    }
    .hrT {
        background:#b57b65;
    }
    /*.hrT { 
        background: url('/img/icons/favicon-32x32.png') no-repeat top center;
        background-size: contain;
        display: block;
        height: 34px;
        border: 0;
        position: relative;
    }
    .hrT:before,
    .hrT:after {
        content: '';
        display: block;
        position: absolute;
        background:#b57b65;
        height: 1px;
        top: 15px;
    }
    .hrT:before {
        left: 0;
        right: 50%;
        margin-right: 10px;
    }
   .hrT:after {
        right: 0;
        left: 50%;
        margin-left: 10px;
    }*/
    .img-full {
        opacity: 0.8;
        background-size: cover;
        -moz-background-size: cover;
        -webkit-background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
    }
    .effect-opacity:hover > .img-full {
        opacity: 1;
    }
    .parallax-item {
        padding-top: 100px;
        padding-bottom: 100px;
        overflow: hidden;
        position: relative;
        width: 100%;
        background-attachment: fixed;
        background-size: cover;
        -moz-background-size: cover;
        -webkit-background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
    }
    .card-profile-image {
        position: relative;
    }
    .card-profile-image img {
        position: absolute;
        left: 50%;
        max-width: 140px;
        transition: all .15s ease;
        transform: translate(-50%,-50%) scale(1);
        border: 3px solid #fff;
        border-radius: .375rem;
    }
    .social-link {
        width: 30px;
        height: 30px;
        border: 1px solid #ddd;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #666;
        border-radius: 50%;
        transition: all 0.3s;
        font-size: 0.9rem;
    }
    .testimonial-img {
        width: 100px;
        height: 100px;
        overflow: hidden;
        border: 3px solid #fff;
        margin: 0px auto 10px;
        border-radius: 100%;
    }

    .carousel-caption{
        width: 67%;
        height: 60%;
    }
    .location-1{
        position: absolute;
        bottom: 0px;
    }
    .center-center{
        justify-content: center;
        align-items: center;
    }
    .center-top{
        justify-content: center;
        align-items: flex-start;
    }
    .center-bottom{
        justify-content: center;
        align-items: flex-end;
    }
    .left-center{
        justify-content: flex-start;
        align-items: center;
    }
    .left-top{
        justify-content: flex-start;
        align-items: flex-start;
    }
    .left-bottom{
        justify-content: flex-start;
        align-items: flex-end;
    }
    .right-center{
        justify-content: flex-end;
        align-items: center;
    }
    .right-top{
        justify-content: flex-end;
        align-items: flex-start;
    }
    .right-bottom{
        justify-content: flex-end;
        align-items: flex-end;
    }
    
</style>
