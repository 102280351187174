function initialState () {
	return {
		navbar: [

		],
	}
    
}
export const navbar = {
  namespaced: true,
  state: initialState,
  actions: {

  },
  mutations: {
    guardarNavbar (state, navbar) {
      state.navbar = navbar
    },
  },
}
