<template>
    <div>
        <encabezado />
        <div class="container mt-5">
            <div class="row text-center">
                <div class="col-lg-10 offset-lg-1">
                    <h2 class="display-1 font-main" v-text="articles.title"/>
                    <hr class="hrT"/>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="offset-md-6 col-md-6 mb-5">
                    <base-input
                        v-model="search" 
                        class="mt-5 font-alt"
                        placeholder="Buscar por título ..."
                        addon-left-icon="fa fa-search"
                    />
                </div>
                <div class="col-lg-12">
                    <div class="row row-grid">
                        <div :class="grid()" v-for="article in filteredList" :key="article.slug">
                            
                            <div class="card h-100 border-0 card-lift--hover shadow">
                                <img class="card-img-top" :src="urlImage(article.image)" :alt="'imagen '+article.title">
                                <div class="card-body">
                                    <p class="text-right font-alt"><small class="text-muted" v-text="article.created_article"/></p>
                                    <h3 class="h6 text-uppercase font-alt" v-text="article.title"/>
                                    <p class="description mt-3" v-text="article.description"/>
                                </div>
                                <div class="card-footer  border-0 bg-white">
                                    <div>
                                        <badge v-for="tag in article.tags" class="bg-default2 text-white font-alt" :key="tag" rounded v-text="tag"/>
                                    </div>
                                    <router-link
                                        :to="{path: 'articles/'+article.slug}"
                                        class="btn btn-outline-default2 mt-4"
                                    >
                                        Leer más
                                    </router-link>
                                </div>
                            </div>
                        
                        </div>
                        
                    </div>
                    <div class="row row-flex justify-content-end">
                        <div class="col-lg-6 mt-5">
                            <a
                                href="https://mareverumblog.wordpress.com/"
                                target="_blank"
                                class="btn btn-block btn-outline-default4 mt-4"
                            >
                                Mas contenido en nuestro blog
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Encabezado from '@/layout/AppEncabezado'
    export default  {
        name: "Articles",
        components: {
            encabezado: Encabezado
        },
        data() {
            return {
               articles: {},
               search: ''
            }
        },
        computed: {
            filteredList() {
                return this.articles.items.filter(article => {
                    return article.title.toLowerCase().includes(this.search.toLowerCase())
                })
            }
        },
        mounted(){
            this.getContent()
        },
        watch: {
        },
        methods: {
            getContent () {
                
                var sections = this.$store.state.services.articlesService.getAll()
                sections.then(response => {
                    if (response.data.code = 200) {
                        this.articles = response.data.data.respuesta
                        if (this.articles.items.length < 1) {
                            this.$router.push('/')
                        }
                        
                    }
                })
                
                
            
            },
            urlImage (image) {
                return process.env.VUE_APP_URL_REPO + 'get/image?filename='+image
            },
            grid(){
                const cant = this.articles.items.length
                var respuesta
                switch (cant) {
                    case 0:
                        respuesta = 'd-none'
                        break;
                    case 1:
                        respuesta = 'col-md-8 offset-md-2'
                        break;
                    case 2:
                        respuesta = 'col-md-6'
                        break;
                    case 3:
                        respuesta = 'col-md-4'
                        break;
                    case 4:
                        respuesta = 'col-md-6'
                        break;
                    default:
                        respuesta = 'col-md-4'
                }
                return respuesta
            },
        }
    }
</script>
<style scoped>
</style>