import Vue from "vue";
import Router from "vue-router";
import AppHeaderIntro from "@/layout/AppHeaderIntro";
import AppHeader from "@/layout/AppHeader";
import NotFound from "@/layout/NotFound";
import AppFooter from "@/layout/AppFooter";
import AppFooterIntro from "@/layout/AppFooterIntro";
import Landing from "@/views/Landing.vue";
import About from "@/views/About.vue";
import Services from "@/views/Services.vue";
import Workflow from "@/views/Workflow.vue";
import Prices from "@/views/Prices.vue";
import Article from "@/views/Article.vue";
import Articles from "@/views/Articles.vue";
import Suscription from "@/views/Suscription.vue";
import Unsuscribe from "@/views/Unsuscribe.vue";
import Inscription from "@/views/Inscription.vue";

Vue.use(Router);

const routes = [
  {
    path: "/",
    name: "Home",
    components: {
      header: AppHeader,
      default: Landing,
      footer: AppFooter
    },
    meta: { Auth: false, title: 'Home | Mare Verum' }
  },
  {
    path: "*",
    name: "404",
    components: {
      default: NotFound,
    },
    meta: { Auth: false, title: '404 Not Found | Mare Verum' }
  },
  {
    path: "/about",
    name: "About",
    components: {
      header: AppHeaderIntro,
      default: About,
      footer: AppFooterIntro
    },
    meta: { Auth: false, title: 'Nosotros | Mare Verum' }
  },
  {
    path: "/services/:slug",
    name: "Services",
    components: {
      header: AppHeaderIntro,
      default: Services,
      footer: AppFooterIntro
    },
    meta: { Auth: false, title: 'Servicios | Mare Verum' }
  },
  {
    path: "/workflow/:slug",
    name: "Workflow",
    components: {
      header: AppHeaderIntro,
      default: Workflow,
      footer: AppFooterIntro
    },
    meta: { Auth: false, title: 'Validaciones | Mare Verum' }
  },
  {
    path: "/prices/:slug",
    name: "Prices",
    components: {
      header: AppHeaderIntro,
      default: Prices,
      footer: AppFooterIntro
    },
    meta: { Auth: false, title: 'Costos | Mare Verum' }
  },
  {
    path: "/articles/:slug",
    name: "Articles",
    components: {
      header: AppHeaderIntro,
      default: Article,
      footer: AppFooterIntro
    },
    meta: { Auth: false, title: 'Articulos | Mare Verum' }
  },
  {
    path: "/articles",
    name: "Articles",
    components: {
      header: AppHeaderIntro,
      default: Articles,
      footer: AppFooterIntro
    },
    meta: { Auth: false, title: 'Todos los Articulos | Mare Verum' }
  },
  {
    path: "/suscription",
    name: "Suscripcion",
    components: {
      header: AppHeaderIntro,
      default: Suscription,
      footer: AppFooterIntro
    },
    meta: { Auth: false, title: 'Suscripciones | Mare Verum' }
  },
  {
    path: "/unsuscribe",
    name: "Unsuscribe",
    components: {
      header: AppHeaderIntro,
      default: Unsuscribe,
      footer: AppFooterIntro
    },
    meta: { Auth: false, title: 'Baja suscripción | Mare Verum' }
  },
  {
    path: "/inscription",
    name: "Inscription",
    components: {
      header: AppHeaderIntro,
      default: Inscription,
      footer: AppFooterIntro
    },
    meta: { Auth: false, title: 'Inscripción | Mare Verum' }
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: "active",
  routes,
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});
export default router

