<template>
    <section id="separador" class="section py-0">
        <div class="parallax-item text-shadow" :style="urlImage(parallax.image)">
            <div class="col-lg-8 offset-lg-2">
                <h2 class="text-center font-main display-1 text-white" v-text="parallax.content.title"/>
                <p class="text-center font-alt text-white" v-text="parallax.content.description"/>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "paralax",
    computed: {
        parallax () {
            return this.$store.state.sections.sections.Paralax
        },
    },
    data () {
        return {
        }
    },
    mounted(){
    },
    methods: {
        urlImage (image) {
            var bg = 'background-image: url("'+process.env.VUE_APP_URL_REPO + 'get/image?filename='+image+'");'
            return bg
        },
    }
}
</script>