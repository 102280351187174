<template>
    <section id="servicios" class="section section-lg bg-secondary">
        <div class="container">
            <div class="row text-center justify-content-center my-lg-5">
                <div class="col-lg-10">
                    <h2 class="display-1 font-main" v-html="services.title"/>
                    <p class="lead" v-html="services.description"/>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <div class="row row-grid">
                        <div :class="grid()" v-for="service in services.items" :key="service.content.title">
                            
                            <card class="border-0 h-100" hover shadow body-classes="py-5 d-flex flex-column align-items-center">
                                <icon v-if="service.use_icon" :name="'fa '+ service.icono" rounded class="mb-4 bg-default3 text-white"/>
                                <img v-else class="card-img-top p-0 m-0" :src="urlImage(service.image)" :alt="service.content.title">
                                
                                <h3 :class="'h6 mt-3 text-default2 text-uppercase font-alt font-weight-bold'" v-text="service.content.title"/>
                                <p class="text-center description mt-3" v-text="service.content.description"/>
                                
                                <div class="align-self-center" style="margin-top: auto;">
                                <router-link
                                    v-if="service.to"
                                    :to="{path: 'services/'+service.to}"
                                    :class="'btn btn-default3 btn-sm mt-4'"
                                    
                                >
                                    mas info
                                </router-link>
                                </div>
                            </card>
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "service",
    computed: {
        services () {
            return this.$store.state.sections.sections.Services
        },
    },
    data () {
        return {
        }
    },
    mounted(){
    },
    methods: {
        urlImage (image) {
            return process.env.VUE_APP_URL_REPO + 'get/image?filename='+image
        },
        grid(){
            const cant = this.services.items.length
            var respuesta
            switch (cant) {
                case 0:
                    respuesta = 'd-none'
                    break;
                case 1:
                    respuesta = 'col-md-8 offset-md-2'
                    break;
                case 2:
                    respuesta = 'col-md-6'
                    break;
                case 3:
                    respuesta = 'col-md-4'
                    break;
                case 4:
                    respuesta = 'col-md-3'
                    break;
                default:
                    respuesta = 'col-md-4'
            }
            return respuesta
        }
    }
}
</script>
